import { useCallback, useMemo } from 'react';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { BillableViewsAggregations, CompensableViewsAggregations, ViewsDashboardTileDetail } from '~api/model/dashboardAggregations';

import { chartColors } from '~constants/colors';

import { formatDate, formatNumber, message } from '~helper/_common';

import { fetchBillableViewsDashboardDetails, useBillableViewsDashboardDetails } from '~modules/billableViewsDashboardDetails';
import { fetchCompensableViewsDashboardDetails, useCompensableViewsDashboardDetails } from '~modules/compensableViewsDashboardDetails';

import { Text } from '~components/atoms/Text';
import { chartSelectionProps, ChartTypeSelectionProps, DetailedChart, RenderChart, renderChartTypeSelection, WithOriginalValues } from '~components/organism/DetailedChart';
import { ComponentCommonProps } from '~components/types';

import style from './ViewsDetailsTile.module.scss';

export type ViewsDetailsTileProps = ComponentCommonProps & {
    creatorId?: string | null;
    mode: 'billableViews' | 'compensableViews';
    data: BillableViewsAggregations['billableViews'] | CompensableViewsAggregations['compensableViews'] | null;
};

const getTooltipLabel = (type: string) => {
    switch (type) {
        case 'liveViews':
        case 'deltaLiveViews':
            return message('common.liveViews');
        case 'replayViews':
        case 'deltaReplayViews':
            return message('common.replayViews');
        default:
            return message('common.total');
    }
};

export const ViewsDetailsTile: React.FC<ViewsDetailsTileProps> = ({
    mode,
    data,
    creatorId,
    'data-test': dataTest = 'viewsDetailsTile'
}) => {
    const totalViewsDashboardDetails = useBillableViewsDashboardDetails();
    const compensableViewsDashboardDetails = useCompensableViewsDashboardDetails();
    const dashboardDetails = mode === 'billableViews' ? totalViewsDashboardDetails : compensableViewsDashboardDetails;
    const chartData = dashboardDetails.dashboardDetails?.details || [];

    const chartProps: ChartTypeSelectionProps = useMemo(() => ({
        shows: {
            disabled: !chartData.some(value => value.liveViews),
            label: message('common.liveViews'),
        },
        clips: {
            disabled: !chartData.some(value => value.replayViews),
            label: message('common.replayViews'),
        },
    }), [ chartData ]);
    const renderChart = useCallback<RenderChart>((chartState, dataTest) => {
        const { shows, clips, total } = chartSelectionProps(chartState, chartProps);
        const noChartDataSelected = !shows?.checked && !clips?.checked;

        return (
            <div
                className={ style.chartArea }
                data-test={ `${dataTest}.mainChart.${chartState.data}` }>
                { chartState.data === 'total' && (
                    <ResponsiveContainer>
                        <AreaChart data={ chartData } margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                            <defs>
                                <linearGradient id="colorGradient_views_total" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors[mode] } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors[mode] } stopOpacity={ 0.05 } />
                                </linearGradient>
                                <linearGradient id="colorGradient_views_shows" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.shows } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.shows } stopOpacity={ 0.05 } />
                                </linearGradient>
                                <linearGradient id="colorGradient_views_clips" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.clips } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.clips } stopOpacity={ 0.05 } />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            { total?.checked && (
                                <Area
                                    dataKey="total"
                                    type="monotone"
                                    stroke={ chartColors[mode] }
                                    fillOpacity={ noChartDataSelected ? 1 : 0 }
                                    fill="url(#colorGradient_views_total)"
                                    data-test={ `${dataTest}.areaChart.total` } />
                            ) }
                            { shows?.checked && (
                                <Area
                                    dataKey="liveViews"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.shows }
                                    fillOpacity={ 1 }
                                    fill="url(#colorGradient_views_shows)"
                                    data-test={ `${dataTest}.areaChart.shows` } />
                            ) }
                            { clips?.checked && (
                                <Area
                                    dataKey="replayViews"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.clips }
                                    fillOpacity={ 1 }
                                    fill="url(#colorGradient_views_clips)"
                                    data-test={ `${dataTest}.areaChart.clips` } />
                            ) }
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => formatDate(value) } />
                            <YAxis
                                width={ 85 }
                                allowDecimals={ false }
                                tickFormatter={ (value) => formatNumber(value) } />
                            <Tooltip
                                formatter={ (value: number, name: keyof ViewsDashboardTileDetail, data: { payload: WithOriginalValues<ViewsDashboardTileDetail> }) => (
                                    [
                                        formatNumber(data.payload[name] as number), getTooltipLabel(name)
                                    ]
                                ) }
                                labelFormatter={ (label, value) => <Text variant="caption">{ value[0]?.payload?.date && formatDate(value[0].payload.date) }</Text> } />
                        </AreaChart>
                    </ResponsiveContainer>
                ) }
                { chartState.data === 'delta' && (
                    <ResponsiveContainer>
                        <BarChart barCategoryGap="10%" barGap='-80%' data={ chartData } margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => formatDate(value) } />
                            <YAxis
                                allowDecimals={ false }
                                tickFormatter={ (value) => formatNumber(value) } />
                            <Tooltip
                                formatter={ (value: number, name: keyof ViewsDashboardTileDetail, data: { payload: WithOriginalValues<ViewsDashboardTileDetail> }) => (
                                    [
                                        formatNumber(data.payload[name] as number), getTooltipLabel(name)
                                    ]
                                ) }
                                labelFormatter={ (label, value) => <Text variant="caption">{ value[0]?.payload?.date && formatDate(value[0].payload.date) }</Text> } />
                            { total?.checked && (
                                <Bar
                                    dataKey="deltaTotal"
                                    type="monotone"
                                    stroke={ chartColors[mode] }
                                    fillOpacity={ noChartDataSelected ? 1 : 0 }
                                    fill={ chartColors[mode] }
                                    data-test={ `${dataTest}.barChart.total` } />
                            ) }
                            { shows?.checked && (
                                <Bar
                                    dataKey="deltaLiveViews"
                                    type="monotone"
                                    stroke={ chartColors.shows }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.shows }
                                    stackId="a"
                                    data-test={ `${dataTest}.barChart.shows` } />
                            ) }
                            { clips?.checked && (
                                <Bar
                                    dataKey="deltaReplayViews"
                                    type="monotone"
                                    stroke={ chartColors.clips }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.clips }
                                    stackId="a"
                                    data-test={ `${dataTest}.barChart.clips` } />
                            ) }
                        </BarChart>
                    </ResponsiveContainer>
                ) }
            </div>
        );
    }, [ totalViewsDashboardDetails, compensableViewsDashboardDetails ]);

    return (
        <DetailedChart
            dataType={ mode === 'billableViews' ? 'billableViews' : 'compensableViews' }
            creatorId={ creatorId }
            headline={ mode === 'billableViews' ? message('dataTile.billableViews.headline') : message('dataTile.compensableViews.headline') }
            label={ message('common.total') }
            value={ formatNumber(data?.total || 0) }
            fetchChartData={ mode === 'billableViews' ? fetchBillableViewsDashboardDetails : fetchCompensableViewsDashboardDetails }
            fetchState={ dashboardDetails.fetchState }
            hasData={ Boolean(chartData.length) }
            renderChart={ renderChart }
            renderChartTypeSelection={ renderChartTypeSelection(chartProps) }
            data-test={ dataTest }
        />
    );
};
