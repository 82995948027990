export type UseScrollFixReturnType = {
    setScrollFix: () => void;
    resetScrollFix: () => void;
}

export const useScrollFix = (): UseScrollFixReturnType => {
    return {
        setScrollFix: () => { document.body.classList.add('scrollFix'); },
        resetScrollFix: () => { document.body.classList.remove('scrollFix'); },
    };
};
