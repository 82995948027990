import LoyaltyIcon from '@mui/icons-material/Loyalty';

import { message } from '~helper/_common';

import { LabeledElement, LabeledElementProps } from '~components/molecules/LabeledElement';

export type BehaviourPointsLabelProps = Omit<LabeledElementProps, 'label' | 'labelIcon'>

export const BehaviourPointsLabel: React.FC<BehaviourPointsLabelProps> = ({
    'data-test': dataTest = 'behaviourPointsLabel',
    ...props
}) => {
    return (
        <LabeledElement
            { ...props }
            label={ message('common.behaviourPoints') }
            labelIcon={ <LoyaltyIcon /> }
            data-test={ dataTest }
        />
    );
};
