import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Checkbox, FormControl, FormControlLabel, FormGroup, Skeleton, Switch, Tab, Tabs, TabsActions, Tooltip as MuiTooltip } from '@mui/material';
import { ActionCreator, AnyAction } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { curry } from 'lodash';

import { DashboardTileDetail } from '~api/model/dashboardAggregations';

import { message } from '~helper/_common';

import { useInitialLoad } from '~hooks/useInitialLoad';

import { DashboardDataType } from '~modules/dashboardDetailTileDateFilter';

import { Headline } from '~components/atoms/Headline';
import { chartSelectionProps } from '~components/organism/DetailedChart/utils';
import { IntervalSelector } from '~components/organism/IntervalSelector';
import { ComponentCommonProps } from '~components/types';

import { ChartData, ChartState, useChartState } from './useChartState';

import style from './DetailedChart.module.scss';

export type RenderChart = (
    chartState: ChartState,
    dataTest: string,
) => React.ReactNode;

export type ChartTypeSelectionProps = Partial<Record<keyof ChartState, {
    label?: string;
    disabled?: boolean;
}>>

export type RenderChartTypeSelection = (
    chartState: ChartState,
    setChartState: (chartState: Partial<ChartState>) => void,
    dataTest: string,
) => React.ReactNode;

export type WithOriginalValues<T extends DashboardTileDetail> = T & {
    original: T;
}

export type DetailedChartProps = ComponentCommonProps & {
    headline: string;
    label: string;
    value: string;
    dataType: DashboardDataType;
    creatorId?: string | null;
    fetchChartData: ActionCreator<AnyAction>;
    fetchState: 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';
    hasData: boolean;
    renderChart: RenderChart;
    renderChartTypeSelection?: RenderChartTypeSelection;
    initialChartState?: ChartState;
};

export const renderChartTypeSelection = curry((
    props: ChartTypeSelectionProps,
    chartState: ChartState,
    setChartState: (chartState: Partial<ChartState>) => void,
    dataTest = 'chartTypeSelection',
) => {
    const handleChartTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChartState({ [event.target.name]: event.target.checked });
    };
    const chartProps = chartSelectionProps(chartState, props);

    return (
        <FormControl component="fieldset" variant="standard">
            <FormGroup>
                { Object.entries(props).map(([ name, values ]) => (
                    <FormControlLabel
                        key={ name }
                        control={
                            <Checkbox
                                { ...chartProps[name as keyof ChartState] }
                                onChange={ handleChartTypeChange }
                                data-test={ `${dataTest}.selectChart.${name}` }
                            />
                        }
                        label={ values.label } />
                )) }
            </FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        { ...chartProps.total }
                        onChange={ handleChartTypeChange }
                        data-test={ `${dataTest}.selectChart.total` } />
                }
                label={
                    <MuiTooltip title={ message('dataTile.details.guides.total.hint') }>
                        <span>{ message('dataTile.details.guides.total') }</span>
                    </MuiTooltip>
                } />
        </FormControl>
    );
});

export const DetailedChart: React.FC<DetailedChartProps> = ({
    headline,
    label,
    value,
    dataType,
    creatorId,
    fetchChartData,
    fetchState,
    hasData,
    renderChart,
    renderChartTypeSelection,
    initialChartState,
    'data-test': dataTest = 'detailedChart'
}) => {
    const dispatch = useDispatch();
    const tabActions = useRef<TabsActions | null>(null);
    const [ chartState, setChartState ] = useChartState(initialChartState);

    useInitialLoad(() => {
        dispatch(fetchChartData(creatorId));
        setTimeout(() => {
            tabActions.current?.updateIndicator();
        }, 800);
    });

    const handleTabChange = (event: React.SyntheticEvent, value: ChartData) => {
        setChartState({ data: value });
    };
    const loading = fetchState === 'INIT' || fetchState === 'FETCHING';
    const dataCanBeShown = !loading && hasData;

    if (fetchState === 'FORBIDDEN') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.forbidden` }
            >
                { message('api.forbidden') }
            </Alert>
        );
    }

    if (fetchState === 'ERROR') {
        return (
            <Alert
                severity="error"
                data-test={ `${dataTest}.error` }
            >
                { message('api.error') }
            </Alert>
        );
    }

    return (
        <div
            className={ style.root }
            data-test={ dataTest }>
            <div
                className={ clsx(style.noGrowingCell, style.mainData) }
                data-test={ `${dataTest}.mainData` }
            >
                <div>
                    <Headline
                        className={ style.headline }
                        variant="h4"
                        loading={ loading }
                        data-test={ `${dataTest}.mainData.headline` }
                    >
                        { headline }
                    </Headline>
                    <Headline
                        className={ style.headline }
                        variant="h5"
                        loading={ loading }
                        data-test={ `${dataTest}.mainData.label` }
                    >
                        { label }
                    </Headline>
                    <Headline
                        className={ style.headline }
                        variant="h5"
                        loading={ loading }
                        data-test={ `${dataTest}.mainData.value` }
                    >
                        { value }
                    </Headline>
                    <IntervalSelector
                        dataType={ dataType }
                        creatorId={ creatorId }
                        disabled={ loading }
                        data-test={ `${dataTest}.mainData.intervalSelector` } />
                </div>
                <div data-test={ `${dataTest}.chartSelection` }>
                    <Tabs
                        centered
                        value={ chartState.data }
                        onChange={ handleTabChange }
                        action={ tabActions }
                        data-test={ `${dataTest}.chartSelection.tabs` }
                    >
                        <Tab
                            value="total"
                            label={ message('common.total') }
                            disabled={ loading }
                            data-test={ `${dataTest}.chartSelection.tabs.total` } />
                        <Tab
                            value="delta"
                            label={ message('common.delta') }
                            disabled={ loading }
                            data-test={ `${dataTest}.chartSelection.tabs.delta` } />
                    </Tabs>
                </div>
                <div className={ style.chartTypeSelection }>
                    { renderChartTypeSelection?.(chartState, setChartState, dataTest) }
                </div>
            </div>
            <div
                className={ style.chartCell }
                data-test={ `${dataTest}.mainChart` }
            >
                { loading && (
                    <Skeleton
                        variant="rectangular"
                        className={ style.chartLoadingIndicator }
                        data-test={ `${dataTest}.mainChart.loading` } />
                ) }
                { dataCanBeShown && renderChart(chartState, dataTest) }
                { !loading && !dataCanBeShown && (
                    <Alert
                        severity="info"
                        data-test={ `${dataTest}.mainChart.noData` }>
                        { message('dataTile.details.chart.noDataAvailable') }
                    </Alert>
                ) }
            </div>
        </div>
    );
};
