import { useRef } from 'react';

import { CollectedCashAggregations } from '~api/model/dashboardAggregations';

import { formatCurrency, message } from '~helper/_common';

import { useShowDetailsTile } from '~hooks/useShowDetailsTile';

import { LivestreamLabel } from '~components/molecules/LivestreamLabel';
import { ReplaysLabel } from '~components/molecules/ReplaysLabel';
import { CashDetailsTile } from '~components/organism/CashDetailsTile';
import { DataTile } from '~components/organism/DataTile';
import { DetailsTile } from '~components/organism/DetailsTile';
import { ComponentCommonProps } from '~components/types';

export type CollectedCashDataTileProps = ComponentCommonProps & {
    loading: boolean;
    data: CollectedCashAggregations['collectedCash'] | null;
    creatorId?: string | null;
    hasDetailsTile?: boolean;
};

export const CollectedCashDataTile: React.FC<CollectedCashDataTileProps> = ({
    className,
    data,
    creatorId,
    loading,
    hasDetailsTile,
    'data-test': dataTest = 'collectedCashDataTile',
}) => {
    const dataTileRef = useRef(null);
    const { showDetailsTile, setShowDetailsTile } = useShowDetailsTile();

    return (
        <>
            { showDetailsTile && (
                <DetailsTile
                    dataTileRef={ dataTileRef }
                    onClose={ () => setShowDetailsTile(false) }
                    data-test={ `${dataTest}.details` }
                >
                    <CashDetailsTile
                        mode="collectedCash"
                        data={ data }
                        creatorId={ creatorId }
                        data-test={ `${dataTest}.details.cash` } />
                </DetailsTile>
            ) }
            <DataTile
                className={ className }
                contentType="collectedCash"
                headline={ message('dataTile.cash.headline') }
                headlineSecondary={ message('common.collected') }
                mainDataValue={ formatCurrency(data?.total || 0) }
                headlineVariant="h4"
                subHeadlineVariant="h5"
                loading={ loading }
                data-test={ dataTest }
                ref={ dataTileRef }
                onExpandClick={ hasDetailsTile ? () => setShowDetailsTile(true) : undefined }
                chartValues={ data?.history?.map(historyEntry => ({ date: historyEntry.date, value: historyEntry.total })) }
            >
                <LivestreamLabel
                    loading={ loading }
                    data-test={ `${dataTest}.liveViews` }>
                    { formatCurrency(data?.liveViews || 0) }
                </LivestreamLabel>
                <ReplaysLabel
                    loading={ loading }
                    data-test={ `${dataTest}.replayViews` }>
                    { formatCurrency(data?.replayViews || 0) }
                </ReplaysLabel>
            </DataTile>
        </>
    );
};
