import { useState } from 'react';

export type UsePositionReturnType = {
    position: DOMRect | null;
    setPosition: (position: DOMRect) => void;
}

export const usePosition = (): UsePositionReturnType => {
    const [ position, setPosition ] = useState<DOMRect | null>(null);

    return {
        position,
        setPosition,
    };
};
