import { useCallback, useMemo } from 'react';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { CashDashboardTileDetail, CollectedCashAggregations, PaidOutCashAggregations } from '~api/model/dashboardAggregations';

import { chartColors } from '~constants/colors';

import { formatCurrency, formatDate, formatNumber, message } from '~helper/_common';

import { fetchCollectedCashDashboardDetails, useCollectedCashDashboardDetails } from '~modules/collectedCashDashboardDetails';
import { fetchPaidOutCashDashboardDetails, usePaidOutCashDashboardDetails } from '~modules/paidOutCashDashboardDetails';

import { Text } from '~components/atoms/Text';
import { chartSelectionProps, ChartTypeSelectionProps, DetailedChart, RenderChart, renderChartTypeSelection, WithOriginalValues } from '~components/organism/DetailedChart';
import { ComponentCommonProps } from '~components/types';

import style from './CashDetailsTile.module.scss';

export type CashDetailsTileProps = ComponentCommonProps & {
    creatorId?: string | null;
    mode: 'collectedCash' | 'paidOutCash';
    data: PaidOutCashAggregations['paidOutCash'] | CollectedCashAggregations['collectedCash'] | null;
};

const getTooltipLabel = (type: string) => {
    switch (type) {
        case 'liveViews':
        case 'deltaLiveViews':
            return message('common.liveViews');
        case 'replayViews':
        case 'deltaReplayViews':
            return message('common.replayViews');
        default:
            return message('common.total');
    }
};

export const CashDetailsTile: React.FC<CashDetailsTileProps> = ({
    mode,
    data,
    creatorId,
    'data-test': dataTest = 'cashDetailsTile',
}) => {
    const collectedCashDashboardDetails = useCollectedCashDashboardDetails();
    const paidOutCashDashboardDetails = usePaidOutCashDashboardDetails();
    const dashboardDetails = mode === 'collectedCash' ? collectedCashDashboardDetails : paidOutCashDashboardDetails;
    const chartData = dashboardDetails.dashboardDetails?.details || [];
    const chartProps: ChartTypeSelectionProps = useMemo(() => ({
        shows: {
            disabled: !chartData.some(value => value.liveViews),
            label: message('common.liveViews'),
        },
        clips: {
            disabled: !chartData.some(value => value.replayViews),
            label: message('common.replayViews'),
        },
    }), [ chartData ]);
    const renderChart = useCallback<RenderChart>((chartState, dataTest) => {
        const { shows, clips, total } = chartSelectionProps(chartState, chartProps);
        const noChartDataSelected = !chartState.shows && !chartState.clips;

        const labelFormatter = (label: string, value: Payload<ValueType, NameType>[]) => {
            const date = value[0]?.payload?.date;

            return (
                <>
                    <Text variant="caption">
                        { `${message('dataTile.cash.headline')} ${message('dataTile.details.cash.collected')}` }
                    </Text>
                    <br />
                    <Text variant="caption">
                        { date && `${formatDate(date)}` }
                    </Text>
                </>
            );
        };

        return (
            <div className={ style.chartArea } data-test={ `${dataTest}.mainChart.${chartState.data}` }>
                { chartState.data === 'total' && (
                    <ResponsiveContainer>
                        <AreaChart data={ chartData } margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                            <defs>
                                <linearGradient id="colorGradient_cash_total" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors[mode] } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors[mode] } stopOpacity={ 0.05 } />
                                </linearGradient>
                                <linearGradient id="colorGradient_cash_shows" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.shows } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.shows } stopOpacity={ 0.05 } />
                                </linearGradient>
                                <linearGradient id="colorGradient_cash_clips" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.clips } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.clips } stopOpacity={ 0.05 } />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            { total?.checked && (
                                <Area
                                    dataKey="total"
                                    type="monotone"
                                    stroke={ chartColors[mode] }
                                    fillOpacity={ noChartDataSelected ? 1 : 0 }
                                    fill="url(#colorGradient_cash_total)"
                                    data-test={ `${dataTest}.areaChart.total` } />
                            ) }
                            { shows?.checked && (
                                <Area
                                    dataKey="liveViews"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.shows }
                                    fillOpacity={ 1 }
                                    fill="url(#colorGradient_cash_shows)"
                                    data-test={ `${dataTest}.areaChart.shows` } />
                            ) }
                            { clips?.checked && (
                                <Area
                                    dataKey="replayViews"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.clips }
                                    fillOpacity={ 1 }
                                    fill="url(#colorGradient_cash_clips)"
                                    data-test={ `${dataTest}.areaChart.clips` } />
                            ) }
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => formatDate(value) } />
                            <YAxis
                                allowDecimals={ false }
                                tickFormatter={ (value) => formatNumber(value) } />
                            <Tooltip
                                formatter={ (value: number, name: keyof CashDashboardTileDetail, data: { payload: WithOriginalValues<CashDashboardTileDetail> }) => (
                                    [
                                        formatCurrency(data.payload[name] as number), getTooltipLabel(name)
                                    ]
                                ) }
                                labelFormatter={ labelFormatter } />
                        </AreaChart>
                    </ResponsiveContainer>
                ) }
                { chartState.data === 'delta' && (
                    <ResponsiveContainer>
                        <BarChart barCategoryGap="10%" barGap="-80%" data={ chartData } margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => formatDate(value) } />
                            <YAxis
                                allowDecimals={ false }
                                tickFormatter={ (value) => formatNumber(value) } />
                            <Tooltip
                                formatter={ (value: number, name: keyof CashDashboardTileDetail, data: { payload: WithOriginalValues<CashDashboardTileDetail> }) => (
                                    [
                                        formatCurrency(data.payload[name] as number), getTooltipLabel(name)
                                    ]
                                ) }
                                labelFormatter={ labelFormatter } />
                            { total?.checked && (
                                <Bar
                                    dataKey="deltaTotal"
                                    type="monotone"
                                    stroke={ chartColors[mode] }
                                    fillOpacity={ noChartDataSelected ? 1 : 0 }
                                    fill={ chartColors[mode] }
                                    data-test={ `${dataTest}.barChart.total` } />
                            ) }
                            { shows?.checked && (
                                <Bar
                                    dataKey="deltaLiveViews"
                                    type="monotone"
                                    stroke={ chartColors.shows }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.shows }
                                    stackId="a"
                                    data-test={ `${dataTest}.barChart.shows` } />
                            ) }
                            { clips?.checked && (
                                <Bar
                                    dataKey="deltaReplayViews"
                                    type="monotone"
                                    stroke={ chartColors.clips }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.clips }
                                    stackId="a"
                                    data-test={ `${dataTest}.barChart.clips` } />
                            ) }
                        </BarChart>
                    </ResponsiveContainer>
                ) }
            </div>
        );
    }, [ dashboardDetails, chartProps ]);

    return (
        <DetailedChart
            dataType={ mode }
            creatorId={ creatorId }
            headline={ message('dataTile.cash.headline') }
            label={ mode === 'collectedCash' ? message('dataTile.details.cash.collected') : message('dataTile.details.cash.paidOut') }
            value={ formatNumber(data?.total || 0) }
            fetchChartData={ mode === 'collectedCash' ? fetchCollectedCashDashboardDetails : fetchPaidOutCashDashboardDetails }
            fetchState={ dashboardDetails.fetchState }
            hasData={ Boolean(chartData.length) }
            renderChart={ renderChart }
            renderChartTypeSelection={ renderChartTypeSelection(chartProps) }
            data-test={ dataTest }
        />
    );
};
