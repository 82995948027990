import { useCallback, useEffect } from 'react';

export const useKeyListener = (eventHandler: () => void, ...keyCodes: string[]): void => {
    const eventHandlerInternal = useCallback(
        (event: KeyboardEvent) => {
            if (keyCodes.includes(event.key)) {
                eventHandler();
            }
        }, []);

    useEffect(() => {
        document.addEventListener('keydown', eventHandlerInternal, false);

        return () => {
            document.removeEventListener('keydown', eventHandlerInternal, false);
        };
    }, []);
};
