import * as React from 'react';
import { useDispatch } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Grid, Popover, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { Mui } from '~constants/mui';

import { formatDate, message } from '~helper/_common';

import { useAnchor } from '~hooks/useAnchor';
import { useFormInputValue } from '~hooks/useFormInputValue';

import {
    DashboardDataType,
    DashboardDetailTileDateFilterPreset,
    setDashboardDetailTileDateFilterCustomRange,
    setDashboardDetailTileDateFilterPreset,
    useDashboardDetailTileDateFilter,
} from '~modules/dashboardDetailTileDateFilter';

import { Button } from '~components/atoms/Button';
import { Headline } from '~components/atoms/Headline';
import { Paper } from '~components/atoms/Paper';
import { ComponentCommonProps } from '~components/types';

import style from './IntervalSelector.module.scss';

export type IntervalSelectorProps = ComponentCommonProps & {
    dataType: DashboardDataType;
    creatorId?: string | null;
    disabled?: boolean;
};

export const IntervalSelector: React.FC<IntervalSelectorProps> = ({
    className,
    disabled,
    creatorId,
    dataType,
    'data-test': dataTest = 'intervalSelector',
}) => {
    const dispatcher = useDispatch();
    const filterState = useDashboardDetailTileDateFilter();
    const { anchor, setAnchor } = useAnchor<HTMLButtonElement>();
    const { value: fromValue, setValue: setFromValue } = useFormInputValue(filterState.customRange?.from);
    const { value: toValue, setValue: setToValue } = useFormInputValue(filterState.customRange?.to);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement> | undefined): void => {
        if (event) {
            event.preventDefault();
            setAnchor(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchor(null);
    };

    const handleFromInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFromValue(event.target.value);
    };

    const handleToInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToValue(event.target.value);
    };

    const handlePresetChange = (event: React.SyntheticEvent, value: DashboardDetailTileDateFilterPreset) => {
        if (!value) {
            return;
        }

        dispatcher(setDashboardDetailTileDateFilterPreset(value, dataType, creatorId));
        setFromValue(undefined);
        setToValue(undefined);
        handleClose();
    };

    const handleCustomRangeApply = () => {
        if (fromValue && toValue) {
            dispatcher(setDashboardDetailTileDateFilterCustomRange({
                from: fromValue,
                to: toValue,
            }, dataType, creatorId));
            handleClose();
        }
    };

    const determineButtonLabel = () => {
        if (filterState.customRange) {
            return `${formatDate(filterState.customRange.from)} - ${formatDate(filterState.customRange.to)}`;
        }

        if (filterState.preset === '365') {
            return message('dataTile.details.intervalSelector.preset.lastYear');
        }

        return message('dataTile.details.intervalSelector.preset.days', filterState.preset || '');
    };

    return (
        <div data-test={ dataTest }>
            <Button
                className={ className }
                onClick={ handleOpen }
                variant="outlined"
                endIcon={ anchor ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                disabled={ disabled }
                data-test={ `${dataTest}.toggleButton` }>
                { determineButtonLabel() }
            </Button>
            <Popover
                open={ !!anchor }
                anchorEl={ anchor }
                onClose={ handleClose }
                anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'left',
                } }
                transformOrigin={ {
                    vertical: 'top',
                    horizontal: 'left',
                } }
                data-test={ `${dataTest}.popover` }>
                <Paper className={ style.popover }>
                    <Grid container spacing={ Mui.gridSpacing }>
                        <Grid
                            item xs={ 6 }
                            data-test={ `${dataTest}.popover.customRange` }>
                            <Grid container spacing={ Mui.gridSpacing }>
                                <Grid item xs={ 12 }>
                                    <Headline
                                        variant="h6"
                                        data-test={ `${dataTest}.popover.customRange.headline` }>
                                        { message('dataTile.details.intervalSelector.customRange') }
                                    </Headline>
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <TextField
                                        label={ message('dataTile.details.intervalSelector.customRange.fromDate') }
                                        type="date"
                                        value={ fromValue }
                                        className={ style.dateField }
                                        InputLabelProps={ {
                                            shrink: true,
                                        } }
                                        onChange={ handleFromInputChange }
                                        size="small"
                                        data-test={ `${dataTest}.popover.customRange.fromDate` }
                                    />
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <TextField
                                        label={ message('dataTile.details.intervalSelector.customRange.toDate') }
                                        type="date"
                                        value={ toValue }
                                        className={ style.dateField }
                                        InputLabelProps={ {
                                            shrink: true,
                                        } }
                                        onChange={ handleToInputChange }
                                        size="small"
                                        data-test={ `${dataTest}.popover.customRange.toDate` }
                                    />
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <Button
                                        size="small"
                                        onClick={ handleCustomRangeApply }
                                        data-test={ `${dataTest}.popover.customRange.applyFilter` }>
                                        { message('dataTile.details.intervalSelector.customRange.applyFilter') }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={ 6 }
                            data-test={ `${dataTest}.popover.presets` }>
                            <Grid container spacing={ Mui.gridSpacing }>
                                <Grid item xs={ 12 }>
                                    <Headline
                                        variant="h6"
                                        data-test={ `${dataTest}.popover.presets.headline` }>
                                        { message('dataTile.details.intervalSelector.presets') }
                                    </Headline>
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <ToggleButtonGroup
                                        exclusive
                                        value={ filterState.preset }
                                        orientation="vertical"
                                        color="primary"
                                        onChange={ handlePresetChange }
                                        fullWidth
                                        data-test={ `${dataTest}.popover.presets.group` }>
                                        <ToggleButton
                                            value="7"
                                            data-test={ `${dataTest}.popover.presets.group.7days` }>
                                            { message('dataTile.details.intervalSelector.preset.days', '7') }
                                        </ToggleButton>
                                        <ToggleButton
                                            value="30"
                                            data-test={ `${dataTest}.popover.presets.group.30days` }>
                                            { message('dataTile.details.intervalSelector.preset.days', '30') }
                                        </ToggleButton>
                                        <ToggleButton
                                            value="90"
                                            data-test={ `${dataTest}.popover.presets.group.90days` }>
                                            { message('dataTile.details.intervalSelector.preset.days', '90') }
                                        </ToggleButton>
                                        <ToggleButton
                                            value="365"
                                            data-test={ `${dataTest}.popover.presets.group.oneYear` }>
                                            { message('dataTile.details.intervalSelector.preset.lastYear') }
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Popover>
        </div>
    );
};
