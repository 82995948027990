import { useRef } from 'react';

import { VideosAggregations } from '~api/model/dashboardAggregations';

import { formatNumber, message } from '~helper/_common';

import { useShowDetailsTile } from '~hooks/useShowDetailsTile';

import { ClipsLabel } from '~components/molecules/ClipsLabel';
import { ShowsLabel } from '~components/molecules/ShowsLabel';
import { DataTile } from '~components/organism/DataTile';
import { DetailsTile } from '~components/organism/DetailsTile';
import { VideosDetailsTile } from '~components/organism/VideosDetailsTile';
import { ComponentCommonProps } from '~components/types';

export type VideosDataTileProps = ComponentCommonProps & {
    loading: boolean;
    data: VideosAggregations['videos'] | null;
    creatorId?: string | null;
    hasDetailsTile?: boolean;
};

export const VideosDataTile: React.FC<VideosDataTileProps> = ({
    className,
    data,
    creatorId,
    loading,
    hasDetailsTile,
    'data-test': dataTest = 'videosDataTile',
}) => {
    const dataTileRef = useRef(null);
    const { showDetailsTile, setShowDetailsTile } = useShowDetailsTile();

    return (
        <>
            { showDetailsTile && (
                <DetailsTile
                    dataTileRef={ dataTileRef }
                    onClose={ () => setShowDetailsTile(false) }
                    data-test={ `${dataTest}.details` }
                >
                    <VideosDetailsTile
                        data={ data }
                        creatorId={ creatorId }
                        data-test={ `${dataTest}.details.videos` }
                    />
                </DetailsTile>
            ) }
            <DataTile
                className={ className }
                contentType="videos"
                headline={ message('dataTile.videos.headline') }
                headlineSecondary={ message('common.total') }
                mainDataValue={ message('common.contents', formatNumber(data?.total || 0)) }
                headlineVariant="h4"
                subHeadlineVariant="h5"
                loading={ loading }
                data-test={ `${dataTest}` }
                ref={ dataTileRef }
                onExpandClick={ hasDetailsTile ? () => setShowDetailsTile(true) : undefined }
                chartValues={ data?.history?.map(historyEntry => ({ date: historyEntry.date, value: historyEntry.total })) }
                chartValueLabel={ message('dataTile.details.content.chart.tooltipLabel') }
            >
                <ShowsLabel
                    loading={ loading }
                    data-test={ `${dataTest}.shows` }>
                    { message('common.shows.amount', formatNumber(data?.shows || 0)) }
                </ShowsLabel>
                <ClipsLabel
                    loading={ loading }
                    data-test={ `${dataTest}.clips` }>
                    { message('common.clips.amount', formatNumber(data?.clips || 0)) }
                </ClipsLabel>
            </DataTile>
        </>
    );
};
