import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { VideosAggregations, VideosDashboardTileDetail } from '~api/model/dashboardAggregations';

import { chartColors } from '~constants/colors';

import { formatDate, formatNumber, message } from '~helper/_common';

import { fetchVideosDashboardDetails, useVideosDashboardDetails } from '~modules/videosDashboardDetails';

import { Text } from '~components/atoms/Text';
import { chartSelectionProps, ChartTypeSelectionProps, DetailedChart, RenderChart, renderChartTypeSelection, WithOriginalValues } from '~components/organism/DetailedChart';
import { ComponentCommonProps } from '~components/types';

import style from './VideosDetailsTile.module.scss';

export type VideosDetailsTileProps = ComponentCommonProps & {
    creatorId?: string | null;
    data: VideosAggregations['videos'] | null;
};

const getTooltipLabel = (type: string) => {
    switch (type) {
        case 'shows':
        case 'deltaShows':
            return message('common.shows');
        case 'clips':
        case 'deltaClips':
            return message('common.clips');
        default:
            return message('common.total');
    }
};

export const VideosDetailsTile: React.FC<VideosDetailsTileProps> = ({
    data,
    creatorId,
    'data-test': dataTest = 'videosDetailsTile'
}) => {
    const dashboardDetails = useVideosDashboardDetails();
    const chartData = dashboardDetails.dashboardDetails?.details || [];

    const chartProps:ChartTypeSelectionProps = useMemo(() => ({
        shows: {
            disabled: !chartData.some(value => value.shows),
            label: message('common.shows'),
        },
        clips: {
            disabled: !chartData.some(value => value.clips),
            label: message('common.clips'),
        }
    }), [ chartData ]);
    const renderChart = useCallback<RenderChart>((chartState, dataTest) => {
        const { shows, clips, total } = chartSelectionProps(chartState, chartProps);
        const noChartDataSelected = !shows?.checked && !clips?.checked;

        return (
            <div className={ style.chartArea } data-test={ `${dataTest}.mainChart.${chartState.data}` }>
                { chartState.data === 'total' && (
                    <ResponsiveContainer>
                        <AreaChart data={ chartData } margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                            <defs>
                                <linearGradient id="colorGradient_videos_total" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.videos } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.videos } stopOpacity={ 0.05 } />
                                </linearGradient>
                                <linearGradient id="colorGradient_videos_shows" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.shows } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.shows } stopOpacity={ 0.05 } />
                                </linearGradient>
                                <linearGradient id="colorGradient_videos_clips" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={ chartColors.clips } stopOpacity={ 0.8 } />
                                    <stop offset="100%" stopColor={ chartColors.clips } stopOpacity={ 0.05 } />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            { total?.checked && (
                                <Area
                                    dataKey="total"
                                    type="monotone"
                                    stroke={ chartColors.videos }
                                    fillOpacity={ noChartDataSelected ? 1 : 0 }
                                    fill="url(#colorGradient_videos_total)"
                                    data-test={ `${dataTest}.areaChart.total` } />
                            ) }
                            { shows?.checked && (
                                <Area
                                    dataKey="shows"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.shows }
                                    fillOpacity={ 1 }
                                    fill="url(#colorGradient_videos_shows)"
                                    data-test={ `${dataTest}.areaChart.shows` } />
                            ) }
                            { clips?.checked && (
                                <Area
                                    dataKey="clips"
                                    type="monotone"
                                    stackId="a"
                                    stroke={ chartColors.clips }
                                    fillOpacity={ 1 }
                                    fill="url(#colorGradient_videos_clips)"
                                    data-test={ `${dataTest}.areaChart.clips` } />
                            ) }
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => formatDate(value) } />
                            <YAxis
                                allowDecimals={ false }
                                tickFormatter={ (value) => formatNumber(value) } />
                            <Tooltip
                                formatter={ (value: number, name: keyof VideosDashboardTileDetail, data: { payload: WithOriginalValues<VideosDashboardTileDetail> }) => (
                                    [
                                        formatNumber(data.payload[name] as number), getTooltipLabel(name)
                                    ]
                                ) }
                                labelFormatter={ (label, value) => <Text variant="caption">{ value[0]?.payload?.date && formatDate(value[0].payload.date) }</Text> } />
                        </AreaChart>
                    </ResponsiveContainer>
                ) }
                { chartState.data === 'delta' && (
                    <ResponsiveContainer>
                        <BarChart barCategoryGap="10%" barGap="-80%" data={ chartData } margin={ { top: 0, left: 0, right: 0, bottom: 0 } }>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={ (value) => formatDate(value) } />
                            <YAxis
                                allowDecimals={ false }
                                tickFormatter={ (value) => formatNumber(value) } />
                            <Tooltip
                                formatter={ (value: number, name: keyof VideosDashboardTileDetail, data: { payload: WithOriginalValues<VideosDashboardTileDetail> }) => (
                                    [
                                        formatNumber(data.payload[name] as number), getTooltipLabel(name)
                                    ]
                                ) }
                                labelFormatter={ (label, value) => <Text variant="caption">{ value[0]?.payload?.date && formatDate(value[0].payload.date) }</Text> } />
                            { total?.checked && (
                                <Bar
                                    dataKey="deltaTotal"
                                    type="monotone"
                                    stroke={ chartColors.videos }
                                    fillOpacity={ noChartDataSelected ? 1 : 0 }
                                    fill={ chartColors.videos }
                                    data-test={ `${dataTest}.barChart.total` } />
                            ) }
                            { shows?.checked && (
                                <Bar
                                    dataKey="deltaShows"
                                    type="monotone"
                                    stroke={ chartColors.shows }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.shows }
                                    stackId="a"
                                    data-test={ `${dataTest}.barChart.shows` } />
                            ) }
                            { clips?.checked && (
                                <Bar
                                    dataKey="deltaClips"
                                    type="monotone"
                                    stroke={ chartColors.clips }
                                    fillOpacity={ 1 }
                                    fill={ chartColors.clips }
                                    stackId="a"
                                    data-test={ `${dataTest}.barChart.clips` } />
                            ) }
                        </BarChart>
                    </ResponsiveContainer>
                ) }
            </div>
        );
    }, [ dashboardDetails ]);

    return (
        <DetailedChart
            dataType="videos"
            creatorId={ creatorId }
            headline={ message('dataTile.videos.headline') }
            label={ message('common.total') }
            value={ formatNumber(data?.total || 0) }
            fetchChartData={ fetchVideosDashboardDetails }
            fetchState={ dashboardDetails.fetchState }
            hasData={ Boolean(dashboardDetails.dashboardDetails?.details.length) }
            renderChartTypeSelection={ renderChartTypeSelection(chartProps) }
            renderChart={ renderChart }
            data-test={ dataTest } />
    );
};
