import { ChartTypeSelectionProps } from './DetailedChart';
import { ChartState } from './useChartState';

type ChartSelectionElementProps = {
    name: string;
    checked: boolean;
    disabled: boolean;
};

export const chartSelectionProps = (
    chartState: ChartState,
    props: ChartTypeSelectionProps,
): Partial<Record<keyof ChartState, ChartSelectionElementProps>> => {
    const result: Partial<Record<keyof ChartState, ChartSelectionElementProps>> = {};

    let enabledCount = 0;
    let checkedCount = 0;

    for (const [ key, values ] of Object.entries(props)) {
        const name = key as keyof ChartState;
        const isEnabled = !values.disabled;
        const isChecked = isEnabled && Boolean(chartState[name]);

        if (isEnabled) {
            enabledCount++;
        }
        if (isChecked) {
            checkedCount++;
        }

        result[name] = {
            name,
            checked: isChecked,
            disabled: !isEnabled,
        };
    }

    const allOrNoneSelected = checkedCount === 0 || checkedCount === enabledCount;

    result.total = {
        name: 'total',
        checked: chartState.total || allOrNoneSelected,
        disabled: allOrNoneSelected,
    };

    return result;
};
