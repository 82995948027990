import { useState } from 'react';

export type UseShowDetailsTileReturnType = {
    showDetailsTile: boolean;
    setShowDetailsTile: (show: boolean) => void;
}

export const useShowDetailsTile = (): UseShowDetailsTileReturnType => {
    const [ showDetailsTile, setShowDetailsTile ] = useState(false);

    return {
        showDetailsTile,
        setShowDetailsTile,
    };
};
