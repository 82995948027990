import { useState } from 'react';

import { useInitialLoad } from '~hooks/useInitialLoad';

export type UseTransitionClassReturnType = {
    transitionClass: string | null;
    setTransitionClass: () => void;
    resetTransitionClass: () => void;
}

export const useTransitionClass = (className: string): UseTransitionClassReturnType => {
    const [ transitionClass, setTransitionClass ] = useState<string | null>(null);
    const setTransitionClassInternal = () => setTransitionClass(className);
    const resetTransitionClassInternal = () => setTransitionClass(null);

    useInitialLoad(() => {
        setTimeout(() => {
            setTransitionClassInternal();
        }, 0);
    });

    return {
        transitionClass,
        setTransitionClass: setTransitionClassInternal,
        resetTransitionClass: resetTransitionClassInternal,
    };
};
