import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import clsx from 'clsx';

import { useKeyListener } from '~hooks/useKeyListener';
import { useOnParameterUpdate } from '~hooks/useOnParameterUpdate';
import { useScrollFix } from '~hooks/useScrollFix';
import { useTransitionClass } from '~hooks/useTransitionClass';

import { Paper } from '~components/atoms/Paper';
import { ComponentCommonProps } from '~components/types';

import { usePosition } from './usePosition';

import style from './DetailsTile.module.scss';

export type DetailsTileProps = ComponentCommonProps & {
    children: React.ReactNode;
    dataTileRef: React.RefObject<HTMLDivElement>;
    onClose: () => void;
}

export type DetailsTileInternalProps = ComponentCommonProps & {
    children: React.ReactNode;
    position: DOMRect;
    onClose: () => void;
}

const ANIMATION_DURATION = 750;

export const DetailsTileInternal: React.FC<DetailsTileInternalProps> = ({
    className,
    children,
    onClose,
    position,
    'data-test': dataTest = 'detailsTile',
}) => {
    const {
        transitionClass: detailsTileTransitionClass,
        resetTransitionClass: resetDetailsTileTransitionClass
    } = useTransitionClass(style.extendedDetailsTile);
    const {
        transitionClass: backdropTransitionClass,
        resetTransitionClass: resetBackdropTransitionClass
    } = useTransitionClass(style.extendedRoot);

    const handleClose = () => {
        resetBackdropTransitionClass();
        resetDetailsTileTransitionClass();

        setTimeout(() => {
            onClose();
        }, ANIMATION_DURATION);
    };

    useKeyListener(handleClose, 'Escape', 'Esc');

    const handleContainerClick: React.MouseEventHandler = (event) => {
        event.stopPropagation();
    };

    return (
        <div
            className={ clsx(style.root, backdropTransitionClass, className) }
            onClick={ handleClose }
            data-test={ dataTest }>
            <Paper
                onClick={ handleContainerClick }
                className={ clsx(style.detailsTile, detailsTileTransitionClass) }
                style={ { top: position.top, left: position.left, height: position.height, width: position.width } }
                data-test={ `${dataTest}.container` }>
                <IconButton
                    className={ style.closeButton }
                    onClick={ handleClose }
                    data-test={ `${dataTest}.container.close` }>
                    <CloseIcon />
                </IconButton>
                { children }
            </Paper>
        </div>
    );
};

// @ts-ignore
export const DetailsTile: React.FC<DetailsTileProps> = ({
    dataTileRef,
    onClose,
    ...otherProps
}) => {
    const { position, setPosition, } = usePosition();
    const { setScrollFix, resetScrollFix } = useScrollFix();

    useOnParameterUpdate(() => {
        if (dataTileRef?.current) {
            setPosition(dataTileRef.current.getBoundingClientRect());
            setScrollFix();
        }
    }, dataTileRef);

    const handleClose = () => {
        resetScrollFix();
        onClose();
    };

    if (!position) {
        return null;
    }

    return ReactDOM.createPortal(
        <DetailsTileInternal
            position={ position }
            onClose={ handleClose }
            { ...otherProps } />,
        document.body,
    );
};
