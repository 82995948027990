import { useState } from 'react';

import { CreatorLevel } from '~constants/creatorLevel';

export type ChartData = 'total' | 'delta';
export type ChartType = 'total' | 'shows' | 'clips' | 'behaviourPoints';

export type ChartState = Record<ChartType, boolean> & Record<CreatorLevel, boolean> & {
    data: ChartData;
};

export const initialChartState: ChartState = {
    total: true,
    shows: true,
    clips: true,
    behaviourPoints: true,
    [CreatorLevel.MEMBER]: false,
    [CreatorLevel.SILVER]: false,
    [CreatorLevel.GOLD]: false,
    [CreatorLevel.PLATINUM]: false,
    data: 'total',
};

export type UseChartStateReturnType = [ChartState, (chartType: Partial<ChartState>) => void];

export const useChartState = (initialState = initialChartState): UseChartStateReturnType => {
    const [ chartState, setChartState ] = useState<ChartState>(initialState);
    const setPartialState = (newState: Partial<ChartState>): void => {
        setChartState({ ...chartState, ...newState });
    };

    return [
        chartState,
        setPartialState,
    ];
};
