import LiveTvIcon from '@mui/icons-material/LiveTv';

import { message } from '~helper/_common';

import { LabeledElement } from '~components/molecules/LabeledElement';

export type ShowsLabelProps = {
    children: React.ReactElement | number | string;
    className?: string;
    loading?: boolean;
    'data-test'?: string;
}

export const ShowsLabel: React.FC<ShowsLabelProps> = ({
    'data-test': dataTest = 'showsLabel',
    ...props
}) => {
    return (
        <LabeledElement
            { ...props }
            label={ message('dataTile.common.shows') }
            labelIcon={ <LiveTvIcon /> }
            data-test={ dataTest } />
    );
};
